/*Mobile*/

.login-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.login {
    color:white;
}
.login-form {
    background-color:#8283A9;
    height:50%;
    width:60%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .email,.password{
  margin:0px 0px;
 
  width:70%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  }
 #email,#password {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:80px;
    height:20px;
    text-align:center;
    margin-top:10px;
    
 }
  .button-login{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:50px;
    outline:none;
    border:none;
    color:white;
  }


/*Desktop*/

@media only screen and (min-width:800px){
.login-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.login {
    color:white;
}
.login-form {
    background-color:#8283A9;
    height:50%;
    width:50%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .email,.password{
  margin:0px 0px;
  width:60%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  }
 #email,#password {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:80px;
    height:20px;
    text-align:center;
    margin-top:10px;
    
 }
  .button-login{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:50px;
    outline:none;
    border:none;
    color:white;
  }
}
@media only screen and (min-width:900px){

    .login-form {
        width:43%;
      }
}
@media only screen and (min-width:1000px){

    .login-form {
        width:40%;
      }
}
@media only screen and (min-width:1200px){

    .login-form {
        width:34%;
      }
}