/* Mobile */
.createtasks-container {
    display:flex;
    align-items: center;

}
.create-form {
    font-size:20px;
    width:70%;
    background-color:rgba(187, 119, 172, 0.63);
    height:400px;
    margin:0px auto;
    border-radius:6px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin-bottom:100px;
    box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.438);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.507);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.432);
}
.create-inputs{
    height:50%;
    width:80%;
    text-align:center;
    border-radius:10px;

}

.create-input-description{
    width:80%;
    height:90%;
    border-radius: 10px;
}
.title {
    height:20%;
    width:100%;
    display:flex;
    margin-top:10px;
    flex-direction:column;
    justify-content: center;
    align-items:center;
}
.description {
    margin-top:10px;
    height:50%;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
}
.create-form-labels{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:50%;
    height:20px;
    text-align:center;
    margin-top:10px;
}
.create-task-controls{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.back-link{
    color:white;
    text-decoration: none;
    cursor:pointer;
    margin-left:50px;
}
.back-button {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    text-decoration: none;
    width:80px;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
}
.back-button:hover {
    animation:buttons 500ms forwards;
}
@keyframes buttons {
    from {
    
    }
    to{
        background-color:rgb(80, 80, 102);
    }
}
.create-button {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
    cursor:pointer;
}
.create-button:hover {
    animation:buttons 500ms forwards;
}
/*Smaller Mobile */
@media only screen and (max-width:320px){
.back-button{
    margin-left:10px;
}
}




@media only screen and (min-width:800px){
    .createtasks-container {
        display:flex;
        align-items: center;
        justify-content: center;
       
    }
    .create-inputs:hover{
        
        animation: color 500ms ;
        animation-fill-mode: forwards;
    
    }
    @keyframes color {
        from {
        
        }
        to{
            background-color:rgb(253, 248, 243);
        }
    }
    .create-form {
        width:60%;
        background-color:rgba(187, 119, 172, 0.63);
        height:400px;
        margin:0px auto;
        border-radius:6px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        margin-bottom:100px;
    }
    .create-inputs{
        height:50%;
        text-align:center;
        background-color:rgb(255, 241, 241);
    }
    .create-input-description{
        width:80%;
        height:90%;
    }
    .title {
        height:20%;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
    }
    .create-button {
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        height:50px;
        width:80px;
        margin-top:10px;
        outline:none;
        border:none;
        color:white;
        cursor:pointer;
    }
    .create-button:hover {
        animation:buttons 500ms forwards;
    }
    .description {
        margin-top:10px;
        height:50%;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        
    }
    .create-form-labels{
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        color:white;
        width:140px;
        height:30px;
        text-align:center;
        margin-top:10px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .create-task-controls{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .back-link{
        color:white;
        text-decoration: none;
        margin-left:100px;
    }
    .back-button {
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        height:50px;
        text-decoration: none;
        width:80px;
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top:10px;
        outline:none;
        border:none;
        color:white;
    }
}
@media only screen and (min-width:1000px){
    .create-form {
        width:50%;
    }
}
@media only screen and (min-width:1100px){
    .create-form {
        width:40%;
    }
}