/*Mobile*/

.container{
    height:100%;
    width:100%;
}
.background {
    background:url('./Asset5.png');
    height:100%;
    width:100%;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    display:flex;
    justify-content:left;
    align-items:center;
}
#login:hover .logo{
    opacity:0
}
@keyframes animateStar{
    from {
        transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
nav {
    
    height:50px;
    width:300px;
    position:fixed;
    top:40px;
    right:20%;
    display:flex;
    justify-content: space-around;
}

#login{
    color:white;
    font-size:25px;
    font-family:Sunflower;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:35px;
    line-height:35px;
    width:40%;
    text-align:center;
    text-decoration:none;
}
#register{
    color:white;
    font-family:Sunflower;
    font-size:25px;
    line-height:35px;
    height:35px;
    width:40%;
    text-align:center;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    text-decoration:none;
}
.logo {
    display:flex;
    width: 305px;
    height: 69px;
    margin-left:10%;
    margin-bottom:10%;
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    line-height: 90px;
    color: #3B647B;
    color:rgb(104, 105, 128);
  
    
}

.dot{
    font-family:roboto;
    font-size:200px;
    height:20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.description-home {
    margin-right:900px;
    position:absolute;
    left:0px;
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    width:100px;
    font-family: 'Sunflower';
    font-style: normal;
    font-size: 40px;
    color: #3B647B;
    color:rgb(104, 105, 128);
    height:50px;
}

/*Desktop*/

@media only screen and (min-width:600px){
.container{
    height:100%;
    width:100%;
}
.background {
    background:url('./Asset5.png');
    height:100%;
    width:100%;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    display:flex;
    justify-content:left;
    align-items:center;
}
nav {
    
    height:50px;
    width:300px;
    position:fixed;
    top:40px;
    right:60px;
    display:flex;
    justify-content: space-around;
}

#login{
    color:white;
    font-size:25px;
    font-family:Sunflower;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:35px;
    line-height:35px;
    width:40%;
    text-align:center;
    text-decoration:none;
}
#register{
    color:white;
    font-family:Sunflower;
    font-size:25px;
    line-height:35px;
    height:35px;
    width:40%;
    text-align:center;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    text-decoration:none;
}
.logo {
    display:flex;
    width: 305px;
    height: 109px;
    margin-left:10%;
    margin-bottom:10%;
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    line-height: 90px;
    color: #3B647B;
    color:rgb(104, 105, 128);
}
.dot{
    font-family:roboto;
    font-size:200px;
    height:20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.description-home {
    height:50px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:600px;
    font-family: 'Sunflower';
    font-style: normal;
    font-size: 40px;
    color: #3B647B;
    color:rgb(104, 105, 128);
}

.cls-23,.cls-24,.cls-25,.cls-26,.cls-18,.cls-36{
   color:red;
}
.cls-19{
    opacity:1;
}
@keyframes animateStar{
    from {
        transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
}