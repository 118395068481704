@import url('https://fonts.googleapis.com/css?family=Sunflower:300&display=swap');

* {
    box-sizing: border-box;
    margin: 0 0;
    padding: 0 0; 
 
}
html {
    margin:0 0;
    padding: 0 0;
    
}
.App {
   
    width:100%;
    height:753px;
}
/* width */
::-webkit-scrollbar {
   display:none;
  }



  
  