/*Mobile*/

.tasks-container{
    background-color:rgb(230, 230, 230);
    font-family:sunflower;
    background-image:url(./Desert-Evening-Desktop-Wallpaper.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
}
.header-panel {
    width:100%;
    color:white;
    text-align:center;
    height:15%;
    display:flex;
    justify-content:space-around;
    align-items: center;

}
.username {
    display:none;
  }
.username-phone{
    padding-top:20px;
    padding-right:30px;
    margin-top:0px;
    text-align:right;
    font-size:18px;
    color:white;
}
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    width:50px;
    position:relative;
    border-radius:10px;
    outline:none;
    border:none;
    margin-left:10px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
}

.task-list {
    list-style:none;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    background-image:url(./Desert-Evening-Desktop-Wallpaper.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
    
}
.task-holder {
    list-style:none;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    transform:translateX(-1000px);
}
.card-to-remove{
    margin-top:80px;
    width:70%;
    background-color:rgba(56, 54, 54, 0.575);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:200px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    
}
.shine {
    animation: cloudcomplete 3s infinite;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
}

li{
    margin-top:80px;
    width:70%;
    background: rgba(2, 0, 36, 0.363);
background: linear-gradient(90deg, rgba(2, 0, 36, 0.486) 0%, rgba(60, 60, 92, 0.527) 0%, rgba(105, 74, 98, 0.527) 100%);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:200px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.541);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.575);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.568);
}
.task-title {
    margin:20px 0px;
}
.create-link {
text-decoration: none;
color:white;
border:2px solid white;
border-radius:6px;
padding:10px 10px;
font-size:23px;
width:100%;
height:100%;
}
.create-link-container {
    display:flex;
    justify-content: center;
}
.logout-link{
    text-decoration: none;
color:white;
border:2px solid white;
border-radius:6px;
padding:10px 10px;
font-size:23px;
}

.check,.complete{
    outline:none;
    border:none;
    height:50%;
    width:120px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    margin-left:10px;
   
}
.check:hover{
    animation: colorchange 1.5s forwards;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
@keyframes colorchange {
    from {

    }
    to {
        background-color:blanchedalmond;
    }
}
.delete {
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:20%;
    background-color:rgba(238, 206, 214, 0);
    border:2px solid white;
    font-size:20px;
    font-family:sunflower;
    color:white;
    margin-right:10px;
}
.delete:hover{
    animation: cloud 1s infinite;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.edit-link:hover {
    animation: cloud 1s infinite;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.task-buttons-container {
    width:100%;
   
    height:50%;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
}
.edit-link {
    text-decoration: none;
    color:white;
    outline:none;
    border:none;
    height:50%;
    width:80px;
    border-radius:20%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 10px;
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 255, 0);
    flex-direction:column;
    height:50px;
    width:100px;
    border-radius:5px;
}
.svg {
    border-radius:5px;
}
.svg-container:hover .shape{
    stroke-width: 6px;
    stroke-dashoffset: 0;
    stroke-dasharray: 360;
    cursor:pointer;
    opacity:1;
}
.svg-icon{
    height:10px;
    width:20px;
}
.shape {
    /*stroke-dasharray: 200 770 ;*/
    stroke-dasharray: 0 400;
    stroke-dashoffset: -0;
    stroke-width: 8px;
    fill: transparent;
    stroke: #ffffff;
    color:white;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    border-radius:50%;
    
  }
  .svg:hover .shape{
    stroke-width: 6px;
    stroke-dashoffset: 0;
    stroke-dasharray: 360;
    cursor:pointer;
    opacity:1;
  }
.svg_text {
    font-size:28px;
   
    cursor:pointer;
    color:white;
    width:100%;
    height:100%;
}
.font-symbol{
    width:40px;
    font-size:10px;
    color:white;
}

.particle-wrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.completed-particles {
    height:10px;
    width:10px;
    background-color:white;
    margin:-85px 10px;
    border-radius:2px;
    opacity:0;

    cursor:pointer;
   
}
.particles{
    height:10px;
    width:10px;
    background-color:white;
    margin:-85px 10px;
    border-radius:2px;
   animation:particle 1s infinite;
    cursor:pointer;

}
@keyframes blur {
    0% { -webkit-filter: blur(0px); }
    50% { -webkit-filter: blur(5px); }
    100% { -webkit-filter: blur(0px); }
  }


/*Mobile small */
@media only screen and (max-width:325px){
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:40px;
}

}


/*Desktop*/
@media only screen and (min-width:800px){
.tasks-container{
    background-color:rgb(230, 230, 230);
    font-family:sunflower;
    background-image:url(./Desert-Evening-Desktop-Wallpaper.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
}
.side-panel {
    width:100%;
    color:white;
    text-align:center;
    height:15%;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:50px;
    width:50px;
    position:relative;
    border-radius:10px;
    outline:none;
    border:none;
    margin:0 10px;
}
.username {
    margin-left:20px;
    display:unset;
  }
.username-phone{
display:none;
  }
.task-list {
    list-style:none;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    
}
.shine {
    animation: cloudcomplete 3s infinite;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
}
li{
    margin-top:80px;
    width:27%;
    background: rgba(2, 0, 36, 0.363);
    background: linear-gradient(90deg, rgba(2, 0, 36, 0.486) 0%, rgba(60, 60, 92, 0.527) 0%, rgba(105, 74, 98, 0.527) 100%);
    color:white;
    padding:10px 10px;
    margin:80px 0px;
    height:220px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:5px;
    border:10px solid rgba(255, 255, 255, 0.103);
    cursor:pointer;
}
.task-title {
    margin:20px 0px;
    font-size:20px;
}
.tasks-symbol {
    font-size:50px;
    margin-top:10px;
}
.card-to-remove{
    margin-top:80px;
    width:27%;
    background-color:rgba(56, 54, 54, 0.575);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:220px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    
}

.check,.complete{
    outline:none;
    border:none;
    height:50%;
    width:100px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
   
}
.check:hover{
    animation: colorchange 500ms forwards;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
@keyframes colorchange {
    from {

    }
    to {
        background-color:rgba(39, 35, 35, 0.445);
    }
}
.delete {
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    border:2px solid white;
    font-size:20px;
    font-family:sunflower;
    color:white;
}
.delete:hover{
    animation: colorchange 1s forwards;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.edit-link:hover {
    animation: colorchange 1s forwards;
    animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.task-buttons-container {
    width:100%;
   
    height:50%;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
    flex-direction:row;
}
.edit-link {
    text-decoration: none;
    color:white;
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}

}
/* Animation for check and delete on hover */

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
@keyframes cloud 
{
   0% {
        background-color: rgba(238, 206, 214, 0.562);
        box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
      }
   100% {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}
@keyframes particle
{
   0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 0 0px rgb(255, 251, 252);
      }
   100% {
        background-color: rgb(255, 255, 255);
        box-shadow: 10px 0 10px 15px rgba(0, 0, 0, 0);
       
    }
}
@keyframes cloudcomplete 
{
   0% {
    text-shadow: 0 0 0px rgb(43, 74, 104), 0 0 20px #fff, 0 0 30px #fcfcfc, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
        background-color: rgba(146, 88, 102, 0.562);
        box-shadow: 0 0 0 0px rgba(68, 63, 63, 0)
      }
   100% {
    text-shadow: 0 10 5px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff, 0 0 80px #ffffff;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}
