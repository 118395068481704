/*Mobile*/ 
.register-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.register{
    color:white;
}
.signup-form {
    background-color:#8283A9;
    height:50%;
    width:60%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .signup-button{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
  }
  .first-name,.last-name{
    width:70%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  .inputs{
      width:100%;
      max-width:80%;
      margin-bottom:5px;
      text-align:center;
  }

  #first-name-label,#last-name-label {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:100px;
    height:20px;
    text-align:center;
    margin-top:10px;
  }


/*Desktop*/
@media only screen and (min-width:800px){
.register-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.register{
    color:white;
}
.signup-form {
    background-color:#8283A9;
    height:50%;
    width:50%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .signup-button{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
    cursor:pointer;
  }

.signup-button:hover{
    background-color:rgb(80, 80, 102);
}
  .inputs{
      max-width:80%;
      width:100%;
      margin-bottom:5px;
      text-align:center;
  }
  .first-name,.last-name{
      margin:0 0;
      width:60%;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  #first-name-label,#last-name-label {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:100px;
    height:20px;
    text-align:center;
    margin-top:10px;
  }
}
@media only screen and (min-width:900px){

    .signup-form {
        width:43%;
      }
}
@media only screen and (min-width:1000px){

    .signup-form {
        width:40%;
      }
}
@media only screen and (min-width:1200px){

    .signup-form {
        width:34%;
      }
}