@import url(https://fonts.googleapis.com/css?family=Sunflower:300&display=swap);
/*Mobile*/

.container{
    height:100%;
    width:100%;
}
.background {
    background:url(/static/media/Asset5.0f175a9e.png);
    height:100%;
    width:100%;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    display:flex;
    justify-content:left;
    align-items:center;
}
#login:hover .logo{
    opacity:0
}
@-webkit-keyframes animateStar{
    from {
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
@keyframes animateStar{
    from {
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
nav {
    
    height:50px;
    width:300px;
    position:fixed;
    top:40px;
    right:20%;
    display:flex;
    justify-content: space-around;
}

#login{
    color:white;
    font-size:25px;
    font-family:Sunflower;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:35px;
    line-height:35px;
    width:40%;
    text-align:center;
    text-decoration:none;
}
#register{
    color:white;
    font-family:Sunflower;
    font-size:25px;
    line-height:35px;
    height:35px;
    width:40%;
    text-align:center;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    text-decoration:none;
}
.logo {
    display:flex;
    width: 305px;
    height: 69px;
    margin-left:10%;
    margin-bottom:10%;
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    line-height: 90px;
    color: #3B647B;
    color:rgb(104, 105, 128);
  
    
}

.dot{
    font-family:roboto;
    font-size:200px;
    height:20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.description-home {
    margin-right:900px;
    position:absolute;
    left:0px;
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    width:100px;
    font-family: 'Sunflower';
    font-style: normal;
    font-size: 40px;
    color: #3B647B;
    color:rgb(104, 105, 128);
    height:50px;
}

/*Desktop*/

@media only screen and (min-width:600px){
.container{
    height:100%;
    width:100%;
}
.background {
    background:url(/static/media/Asset5.0f175a9e.png);
    height:100%;
    width:100%;
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    display:flex;
    justify-content:left;
    align-items:center;
}
nav {
    
    height:50px;
    width:300px;
    position:fixed;
    top:40px;
    right:60px;
    display:flex;
    justify-content: space-around;
}

#login{
    color:white;
    font-size:25px;
    font-family:Sunflower;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:35px;
    line-height:35px;
    width:40%;
    text-align:center;
    text-decoration:none;
}
#register{
    color:white;
    font-family:Sunflower;
    font-size:25px;
    line-height:35px;
    height:35px;
    width:40%;
    text-align:center;
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    text-decoration:none;
}
.logo {
    display:flex;
    width: 305px;
    height: 109px;
    margin-left:10%;
    margin-bottom:10%;
    font-family: 'Sunflower';
    font-style: normal;
    font-weight: 300;
    font-size: 120px;
    line-height: 90px;
    color: #3B647B;
    color:rgb(104, 105, 128);
}
.dot{
    font-family:roboto;
    font-size:200px;
    height:20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.description-home {
    height:50px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:600px;
    font-family: 'Sunflower';
    font-style: normal;
    font-size: 40px;
    color: #3B647B;
    color:rgb(104, 105, 128);
}

.cls-23,.cls-24,.cls-25,.cls-26,.cls-18,.cls-36{
   color:red;
}
.cls-19{
    opacity:1;
}
@-webkit-keyframes animateStar{
    from {
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
@keyframes animateStar{
    from {
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
        opacity:0
    }
    to {
        opacity:1
    }
}
}
/*Mobile*/

.login-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.login {
    color:white;
}
.login-form {
    background-color:#8283A9;
    height:50%;
    width:60%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .email,.password{
  margin:0px 0px;
 
  width:70%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  }
 #email,#password {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:80px;
    height:20px;
    text-align:center;
    margin-top:10px;
    
 }
  .button-login{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:50px;
    outline:none;
    border:none;
    color:white;
  }


/*Desktop*/

@media only screen and (min-width:800px){
.login-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.login {
    color:white;
}
.login-form {
    background-color:#8283A9;
    height:50%;
    width:50%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .email,.password{
  margin:0px 0px;
  width:60%;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  }
 #email,#password {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:80px;
    height:20px;
    text-align:center;
    margin-top:10px;
    
 }
  .button-login{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:50px;
    outline:none;
    border:none;
    color:white;
  }
}
@media only screen and (min-width:900px){

    .login-form {
        width:43%;
      }
}
@media only screen and (min-width:1000px){

    .login-form {
        width:40%;
      }
}
@media only screen and (min-width:1200px){

    .login-form {
        width:34%;
      }
}
/*Mobile*/

.tasks-container{
    background-color:rgb(230, 230, 230);
    font-family:sunflower;
    background-image:url(/static/media/Desert-Evening-Desktop-Wallpaper.bc37a205.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
}
.header-panel {
    width:100%;
    color:white;
    text-align:center;
    height:15%;
    display:flex;
    justify-content:space-around;
    align-items: center;

}
.username {
    display:none;
  }
.username-phone{
    padding-top:20px;
    padding-right:30px;
    margin-top:0px;
    text-align:right;
    font-size:18px;
    color:white;
}
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:50px;
    display:flex;
    justify-content: center;
    align-items: center;
    width:50px;
    position:relative;
    border-radius:10px;
    outline:none;
    border:none;
    margin-left:10px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
}

.task-list {
    list-style:none;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    background-image:url(/static/media/Desert-Evening-Desktop-Wallpaper.bc37a205.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
    
}
.task-holder {
    list-style:none;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    -webkit-transform:translateX(-1000px);
            transform:translateX(-1000px);
}
.card-to-remove{
    margin-top:80px;
    width:70%;
    background-color:rgba(56, 54, 54, 0.575);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:200px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    
}
.shine {
    -webkit-animation: cloudcomplete 3s infinite;
            animation: cloudcomplete 3s infinite;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
}

li{
    margin-top:80px;
    width:70%;
    background: rgba(2, 0, 36, 0.363);
background: -webkit-gradient(linear, left top, right top, from(rgba(2, 0, 36, 0.486)), color-stop(0%, rgba(60, 60, 92, 0.527)), to(rgba(105, 74, 98, 0.527)));
background: linear-gradient(90deg, rgba(2, 0, 36, 0.486) 0%, rgba(60, 60, 92, 0.527) 0%, rgba(105, 74, 98, 0.527) 100%);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:200px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.541);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.575);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.568);
}
.task-title {
    margin:20px 0px;
}
.create-link {
text-decoration: none;
color:white;
border:2px solid white;
border-radius:6px;
padding:10px 10px;
font-size:23px;
width:100%;
height:100%;
}
.create-link-container {
    display:flex;
    justify-content: center;
}
.logout-link{
    text-decoration: none;
color:white;
border:2px solid white;
border-radius:6px;
padding:10px 10px;
font-size:23px;
}

.check,.complete{
    outline:none;
    border:none;
    height:50%;
    width:120px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    margin-left:10px;
   
}
.check:hover{
    -webkit-animation: colorchange 1.5s forwards;
            animation: colorchange 1.5s forwards;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
@-webkit-keyframes colorchange {
    from {

    }
    to {
        background-color:blanchedalmond;
    }
}
@keyframes colorchange {
    from {

    }
    to {
        background-color:blanchedalmond;
    }
}
.delete {
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:20%;
    background-color:rgba(238, 206, 214, 0);
    border:2px solid white;
    font-size:20px;
    font-family:sunflower;
    color:white;
    margin-right:10px;
}
.delete:hover{
    -webkit-animation: cloud 1s infinite;
            animation: cloud 1s infinite;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.edit-link:hover {
    -webkit-animation: cloud 1s infinite;
            animation: cloud 1s infinite;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.task-buttons-container {
    width:100%;
   
    height:50%;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
}
.edit-link {
    text-decoration: none;
    color:white;
    outline:none;
    border:none;
    height:50%;
    width:80px;
    border-radius:20%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 10px;
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 255, 0);
    flex-direction:column;
    height:50px;
    width:100px;
    border-radius:5px;
}
.svg {
    border-radius:5px;
}
.svg-container:hover .shape{
    stroke-width: 6px;
    stroke-dashoffset: 0;
    stroke-dasharray: 360;
    cursor:pointer;
    opacity:1;
}
.svg-icon{
    height:10px;
    width:20px;
}
.shape {
    /*stroke-dasharray: 200 770 ;*/
    stroke-dasharray: 0 400;
    stroke-dashoffset: -0;
    stroke-width: 8px;
    fill: transparent;
    stroke: #ffffff;
    color:white;
    -webkit-transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
    border-radius:50%;
    
  }
  .svg:hover .shape{
    stroke-width: 6px;
    stroke-dashoffset: 0;
    stroke-dasharray: 360;
    cursor:pointer;
    opacity:1;
  }
.svg_text {
    font-size:28px;
   
    cursor:pointer;
    color:white;
    width:100%;
    height:100%;
}
.font-symbol{
    width:40px;
    font-size:10px;
    color:white;
}

.particle-wrapper{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.completed-particles {
    height:10px;
    width:10px;
    background-color:white;
    margin:-85px 10px;
    border-radius:2px;
    opacity:0;

    cursor:pointer;
   
}
.particles{
    height:10px;
    width:10px;
    background-color:white;
    margin:-85px 10px;
    border-radius:2px;
   -webkit-animation:particle 1s infinite;
           animation:particle 1s infinite;
    cursor:pointer;

}
@-webkit-keyframes blur {
    0% { -webkit-filter: blur(0px); }
    50% { -webkit-filter: blur(5px); }
    100% { -webkit-filter: blur(0px); }
  }
@keyframes blur {
    0% { -webkit-filter: blur(0px); }
    50% { -webkit-filter: blur(5px); }
    100% { -webkit-filter: blur(0px); }
  }


/*Mobile small */
@media only screen and (max-width:325px){
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:40px;
}

}


/*Desktop*/
@media only screen and (min-width:800px){
.tasks-container{
    background-color:rgb(230, 230, 230);
    font-family:sunflower;
    background-image:url(/static/media/Desert-Evening-Desktop-Wallpaper.bc37a205.png);
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    height:100%;
}
.side-panel {
    width:100%;
    color:white;
    text-align:center;
    height:15%;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
}
.profile-pic {
    background-color:rgb(60, 60, 92);
    height:50px;
    width:50px;
    position:relative;
    border-radius:10px;
    outline:none;
    border:none;
    margin:0 10px;
}
.username {
    margin-left:20px;
    display:unset;
  }
.username-phone{
display:none;
  }
.task-list {
    list-style:none;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    
}
.shine {
    -webkit-animation: cloudcomplete 3s infinite;
            animation: cloudcomplete 3s infinite;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
}
li{
    margin-top:80px;
    width:27%;
    background: rgba(2, 0, 36, 0.363);
    background: -webkit-gradient(linear, left top, right top, from(rgba(2, 0, 36, 0.486)), color-stop(0%, rgba(60, 60, 92, 0.527)), to(rgba(105, 74, 98, 0.527)));
    background: linear-gradient(90deg, rgba(2, 0, 36, 0.486) 0%, rgba(60, 60, 92, 0.527) 0%, rgba(105, 74, 98, 0.527) 100%);
    color:white;
    padding:10px 10px;
    margin:80px 0px;
    height:220px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:5px;
    border:10px solid rgba(255, 255, 255, 0.103);
    cursor:pointer;
}
.task-title {
    margin:20px 0px;
    font-size:20px;
}
.tasks-symbol {
    font-size:50px;
    margin-top:10px;
}
.card-to-remove{
    margin-top:80px;
    width:27%;
    background-color:rgba(56, 54, 54, 0.575);
    color:white;
    padding:10px 0px;
    margin:80px auto;
    height:220px;
    display:flex;
    align-items:center;
    flex-direction:column;
    justify-content: center;
    border-radius:6px;
    box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0,0,0,0.66);
    
}

.check,.complete{
    outline:none;
    border:none;
    height:50%;
    width:100px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
   
}
.check:hover{
    -webkit-animation: colorchange 500ms forwards;
            animation: colorchange 500ms forwards;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
@-webkit-keyframes colorchange {
    from {

    }
    to {
        background-color:rgba(39, 35, 35, 0.445);
    }
}
@keyframes colorchange {
    from {

    }
    to {
        background-color:rgba(39, 35, 35, 0.445);
    }
}
.delete {
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    border:2px solid white;
    font-size:20px;
    font-family:sunflower;
    color:white;
}
.delete:hover{
    -webkit-animation: colorchange 1s forwards;
            animation: colorchange 1s forwards;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.edit-link:hover {
    -webkit-animation: colorchange 1s forwards;
            animation: colorchange 1s forwards;
    -webkit-animation-timing-function: cubic-bezier(.17,.67,.83,.67);
            animation-timing-function: cubic-bezier(.17,.67,.83,.67);
    cursor:pointer;
}
.task-buttons-container {
    width:100%;
   
    height:50%;
    display:flex;
    justify-content: space-evenly;
    align-items:center;
    flex-direction:row;
}
.edit-link {
    text-decoration: none;
    color:white;
    outline:none;
    border:none;
    height:50%;
    width:90px;
    border-radius:10%;
    background-color:rgba(238, 206, 214, 0);
    color:white;
    border:2px solid white;
    font-family:sunflower;
    font-size:20px;
    display:flex;
    justify-content: center;
    align-items: center;
}

}
/* Animation for check and delete on hover */

@-webkit-keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
@-webkit-keyframes cloud 
{
   0% {
        background-color: rgba(238, 206, 214, 0.562);
        box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
      }
   100% {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}
@keyframes cloud 
{
   0% {
        background-color: rgba(238, 206, 214, 0.562);
        box-shadow: 0 0 0 0px rgba(238, 206, 214, 0.562);
      }
   100% {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}
@-webkit-keyframes particle
{
   0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 0 0px rgb(255, 251, 252);
      }
   100% {
        background-color: rgb(255, 255, 255);
        box-shadow: 10px 0 10px 15px rgba(0, 0, 0, 0);
       
    }
}
@keyframes particle
{
   0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 0 0px rgb(255, 251, 252);
      }
   100% {
        background-color: rgb(255, 255, 255);
        box-shadow: 10px 0 10px 15px rgba(0, 0, 0, 0);
       
    }
}
@-webkit-keyframes cloudcomplete 
{
   0% {
    text-shadow: 0 0 0px rgb(43, 74, 104), 0 0 20px #fff, 0 0 30px #fcfcfc, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
        background-color: rgba(146, 88, 102, 0.562);
        box-shadow: 0 0 0 0px rgba(68, 63, 63, 0)
      }
   100% {
    text-shadow: 0 10 5px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff, 0 0 80px #ffffff;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}
@keyframes cloudcomplete 
{
   0% {
    text-shadow: 0 0 0px rgb(43, 74, 104), 0 0 20px #fff, 0 0 30px #fcfcfc, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff;
        background-color: rgba(146, 88, 102, 0.562);
        box-shadow: 0 0 0 0px rgba(68, 63, 63, 0)
      }
   100% {
    text-shadow: 0 10 5px #fff, 0 0 30px #ffffff, 0 0 40px #ffffff, 0 0 50px #ffffff, 0 0 60px #ffffff, 0 0 70px #ffffff, 0 0 80px #ffffff;
        background-color: rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
        color:rgba(14, 13, 13, 0);
    }
}

/*Mobile*/ 
.register-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.register{
    color:white;
}
.signup-form {
    background-color:#8283A9;
    height:50%;
    width:60%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .signup-button{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
  }
  .first-name,.last-name{
    width:70%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  .inputs{
      width:100%;
      max-width:80%;
      margin-bottom:5px;
      text-align:center;
  }

  #first-name-label,#last-name-label {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:100px;
    height:20px;
    text-align:center;
    margin-top:10px;
  }


/*Desktop*/
@media only screen and (min-width:800px){
.register-container {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}
.register{
    color:white;
}
.signup-form {
    background-color:#8283A9;
    height:50%;
    width:50%;
    margin:0 auto;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius:10px;
  }
  .signup-button{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
    cursor:pointer;
  }

.signup-button:hover{
    background-color:rgb(80, 80, 102);
}
  .inputs{
      max-width:80%;
      width:100%;
      margin-bottom:5px;
      text-align:center;
  }
  .first-name,.last-name{
      margin:0 0;
      width:60%;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  #first-name-label,#last-name-label {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:100px;
    height:20px;
    text-align:center;
    margin-top:10px;
  }
}
@media only screen and (min-width:900px){

    .signup-form {
        width:43%;
      }
}
@media only screen and (min-width:1000px){

    .signup-form {
        width:40%;
      }
}
@media only screen and (min-width:1200px){

    .signup-form {
        width:34%;
      }
}
/* Mobile */
.createtasks-container {
    display:flex;
    align-items: center;

}
.create-form {
    font-size:20px;
    width:70%;
    background-color:rgba(187, 119, 172, 0.63);
    height:400px;
    margin:0px auto;
    border-radius:6px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    margin-bottom:100px;
    box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.438);
    -webkit-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.507);
    -moz-box-shadow: 10px 55px 97px -20px rgba(0, 0, 0, 0.432);
}
.create-inputs{
    height:50%;
    width:80%;
    text-align:center;
    border-radius:10px;

}

.create-input-description{
    width:80%;
    height:90%;
    border-radius: 10px;
}
.title {
    height:20%;
    width:100%;
    display:flex;
    margin-top:10px;
    flex-direction:column;
    justify-content: center;
    align-items:center;
}
.description {
    margin-top:10px;
    height:50%;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
}
.create-form-labels{
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    color:white;
    width:50%;
    height:20px;
    text-align:center;
    margin-top:10px;
}
.create-task-controls{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.back-link{
    color:white;
    text-decoration: none;
    cursor:pointer;
    margin-left:50px;
}
.back-button {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    text-decoration: none;
    width:80px;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
}
.back-button:hover {
    -webkit-animation:buttons 500ms forwards;
            animation:buttons 500ms forwards;
}
@-webkit-keyframes buttons {
    from {
    
    }
    to{
        background-color:rgb(80, 80, 102);
    }
}
@keyframes buttons {
    from {
    
    }
    to{
        background-color:rgb(80, 80, 102);
    }
}
.create-button {
    background-color:rgb(104, 105, 128);
    border-radius:6px;
    height:50px;
    width:80px;
    margin-top:10px;
    outline:none;
    border:none;
    color:white;
    cursor:pointer;
}
.create-button:hover {
    -webkit-animation:buttons 500ms forwards;
            animation:buttons 500ms forwards;
}
/*Smaller Mobile */
@media only screen and (max-width:320px){
.back-button{
    margin-left:10px;
}
}




@media only screen and (min-width:800px){
    .createtasks-container {
        display:flex;
        align-items: center;
        justify-content: center;
       
    }
    .create-inputs:hover{
        
        -webkit-animation: color 500ms ;
        
                animation: color 500ms ;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
    
    }
    @-webkit-keyframes color {
        from {
        
        }
        to{
            background-color:rgb(253, 248, 243);
        }
    }
    @keyframes color {
        from {
        
        }
        to{
            background-color:rgb(253, 248, 243);
        }
    }
    .create-form {
        width:60%;
        background-color:rgba(187, 119, 172, 0.63);
        height:400px;
        margin:0px auto;
        border-radius:6px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        margin-bottom:100px;
    }
    .create-inputs{
        height:50%;
        text-align:center;
        background-color:rgb(255, 241, 241);
    }
    .create-input-description{
        width:80%;
        height:90%;
    }
    .title {
        height:20%;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
    }
    .create-button {
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        height:50px;
        width:80px;
        margin-top:10px;
        outline:none;
        border:none;
        color:white;
        cursor:pointer;
    }
    .create-button:hover {
        -webkit-animation:buttons 500ms forwards;
                animation:buttons 500ms forwards;
    }
    .description {
        margin-top:10px;
        height:50%;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items:center;
        
    }
    .create-form-labels{
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        color:white;
        width:140px;
        height:30px;
        text-align:center;
        margin-top:10px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .create-task-controls{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .back-link{
        color:white;
        text-decoration: none;
        margin-left:100px;
    }
    .back-button {
        background-color:rgb(104, 105, 128);
        border-radius:6px;
        height:50px;
        text-decoration: none;
        width:80px;
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top:10px;
        outline:none;
        border:none;
        color:white;
    }
}
@media only screen and (min-width:1000px){
    .create-form {
        width:50%;
    }
}
@media only screen and (min-width:1100px){
    .create-form {
        width:40%;
    }
}
* {
    box-sizing: border-box;
    margin: 0 0;
    padding: 0 0; 
 
}
html {
    margin:0 0;
    padding: 0 0;
    
}
.App {
   
    width:100%;
    height:753px;
}
/* width */
::-webkit-scrollbar {
   display:none;
  }



  
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

